/**
 * Renders the App Store buttons component.
 *
 * This component is responsible for rendering the App Store and Google Play buttons.
 * It uses the `useIsSsr` hook to determine if the component is being server-side rendered.
 * If not, it sets the device type based on the user agent.
 *
 * The component then checks if the device is Android and only the Google button link is available,
 * or if the device is iOS and only the Apple button link is available.
 * Depending on these conditions, it renders the appropriate button(s).
 *
 * @component
 * @param {Object} props.appleButton - The Apple App Store button content. This object should contain the link for the Apple App Store.
 * @param {Object} props.googleButton - The Google Play Store button content. This object should contain the link for the Google Play Store.
 * @param {Object} props.appleImage - The Apple App Store button image. This object should contain the image source for the Apple App Store button.
 * @param {Object} props.googleImage - The Google Play Store button image. This object should contain the image source for the Google Play Store button.
 * @param {string} props.navLinkSection - The navigation link section. This is used for tracking purposes. This value should be the app store buttons renderer's section name
 * @returns {JSX.Element} The rendered component. This component will render the App Store and Google Play buttons based on the device type and available button links.
 */
import { Box } from '@mui/material'
import { AchieveLink } from 'components/AchieveLink'
import useIsSsr from 'hooks/useIsSsr'
import { useEffect, useState } from 'react'
import { actualDevice, DEVICES } from 'utils/shared'
import styles from './AppStoreButtons.module.scss'
import { LinkButton, MediaImageStandard } from 'components/Contentful'
import PropTypes from 'prop-types'

const AppStoreButtons = ({
  appleButton,
  googleButton,
  appleImage,
  googleImage,
  navLinkSection,
  priority = false,
}) => {
  const [device, setDevice] = useState(null)
  const isSsr = useIsSsr()

  // useEffect to set the device type based on the user agent
  // doesn't run on the server because there is no user agent on the server
  useEffect(() => {
    if (!isSsr) {
      setDevice(actualDevice(navigator.userAgent))
    }
  }, [isSsr])

  // Check if the device is Android and only Google button link is available
  const onlyAndroidNoImage =
    device === DEVICES.android && googleImage == undefined && googleButton?.linkHref != undefined
  // Check if the device is iOS and only Apple button link is available
  const onlyAppleNoImage =
    device === DEVICES.ios && appleImage == undefined && appleButton?.linkHref != undefined

  // If the user is on ios and the ios image is not available, render the apple button as a outlined button without the apple store image
  if (onlyAppleNoImage) {
    return (
      <LinkButton
        target="blank"
        variant="outlined"
        content={appleButton}
        className={styles['outline-link']}
        typographicOptions={{ variant: 'displayS10' }}
      />
    )
  }

  // If the user is on android and the android image is not available, render the google button as a outlined button without the google store image
  if (onlyAndroidNoImage) {
    return (
      <LinkButton
        target="blank"
        variant="outlined"
        content={googleButton}
        className={styles['outline-link']}
        typographicOptions={{ variant: 'displayS10' }}
      />
    )
  }

  // Define the AppleButton component
  // This component will exist if the device is desktop or ios, and the appleImage exists
  const AppleButton = (device === DEVICES.desktop || device === DEVICES.ios) && appleImage && (
    <AchieveLink
      href={appleButton?.linkHref}
      noLink
      track={{
        list_name: 'APPLE APP STORE BUTTON',
        click_id: appleImage?.mediaAltText || 'native-download-apple-en',
        click_text: `Achieve-Web | ${appleImage?.mediaAltText || 'native-download-apple-en'}`,
        click_position: 1,
        nav_link_section: navLinkSection,
      }}
      data-testid="apple-app-store-btn"
    >
      <div className={styles['button-apple']} data-testid="apple-button-image">
        <MediaImageStandard content={appleImage?.mediaContent || appleImage} priority={priority} />
      </div>
    </AchieveLink>
  )

  // Define the GoogleButton component
  // This component will exist if the device is desktop or android, and the googleImage exists
  const GoogleButton = (device === DEVICES.desktop || device === DEVICES.android) &&
    googleImage && (
      <AchieveLink
        href={googleButton?.linkHref}
        noLink
        track={{
          list_name: 'GOOGLE APP STORE BUTTON',
          click_id: googleImage?.mediaAltText || 'native-download-google-en',
          click_text: `Achieve-Web | ${googleImage?.mediaAltText || 'native-download-google-en'}`,
          click_position: 2,
          nav_link_section: navLinkSection,
        }}
        data-testid="google-app-store-btn"
      >
        <div className={styles['button-google']} data-testid="google-button-image">
          <MediaImageStandard
            content={googleImage?.mediaContent || googleImage}
            priority={priority}
          />
        </div>
      </AchieveLink>
    )

  // If neither AppleButton nor GoogleButton exist, return null to avoid rendering an empty Box component
  if (!AppleButton && !GoogleButton) return null

  // If either AppleButton or GoogleButton exist, render them inside a Box component with specific styles
  return (
    <Box className={styles['app-store-buttons']} data-testid="app-store-btn-container">
      {AppleButton}
      {GoogleButton}
    </Box>
  )
}

AppStoreButtons.propTypes = {
  appleButton: PropTypes.object,
  googleButton: PropTypes.object,
  appleImage: PropTypes.object,
  googleImage: PropTypes.object,
  navLinkSection: PropTypes.string.isRequired,
}

export default AppStoreButtons
