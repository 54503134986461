import styles from './ValueProps.module.scss'
import { Grid, Box, Collapse, AchieveTheme } from '@achieve/sunbeam'
import Section from 'components/Section'
import { CardValueProp } from 'components/Cards'
import { TitleDivider } from 'components/TitleDivider'
import { BLOCKS } from '@contentful/rich-text-types'
import { LinkButton, Typography, MediaImageStandard } from 'components/Contentful'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { useMemoizedContentGetter } from 'utils/contentful'
import classNames from 'classnames'
import { Carousel } from 'components/Carousels'
import { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Button } from 'components/Button'

function Card({ item: { cardImage, cardTitleText, cardText }, priority }) {
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [quote] = cardText?.content ?? []
  return (
    <Grid
      container
      direction={{ xs: 'column-reverse' }}
      justifyContent={{ xs: 'flex-end' }}
      alignItems={{ xs: 'left' }}
    >
      <Grid item xs={6} className={styles['value-card-grid-carousel']}>
        <div className={styles['image-container']}>
          <MediaImageStandard
            layout={'fixed'}
            content={cardImage}
            priority={priority}
            width={60}
            height={60}
          />
        </div>
        <Typography
          className={styles['carousel-item-title']}
          content={cardTitleText}
          fontWeight="bold"
          variant={isMobile ? 'displayS30' : 'displayM20'}
        />
        <Typography
          className={styles['carousel-item-quote']}
          content={quote}
          fontWeight="regular"
          variant="displayXS30"
        />
      </Grid>
    </Grid>
  )
}

function ValuePropsModule({
  idContent,
  title,
  eyebrow,
  subtitle,
  grid,
  theme,
  linkButton,
  arrow,
  handwritten,
  backgroundConfigJson,
  componentVariant,
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const jsonContent = backgroundConfigJson?.jsonContent || {}
  const backgroundColor = theme?.theme[0]
    ? AchieveTheme?.sb?.colors?.primary?.highlightBlue
    : AchieveTheme?.sb?.colors?.neutral?.white
  const variant = {
    title: isMobile ? 'displayS20' : 'displayM20',
    titleFontWeight: jsonContent?.titleFontWeight ?? 'bold',
    subtitle: isMobile ? 'displayXS30' : 'ascendDisplaySm',
    subtitleFontWeight: jsonContent?.subtitleFontWeight ?? 'regular',
  }

  const breakline = ['aboutUsCulture', 'valueSeparatorProps'].includes(componentVariant)
  const containerGrid = !['aboutUsCulture', 'partnersValueProps'].includes(componentVariant)

  const itemsByrow = jsonContent?.itemsByrow || 3
  const carouselOnMobile = jsonContent?.carouselOnMobile || false
  const dividerOnMobile = jsonContent?.dividerOnMobile || false
  const marginOnMobile = jsonContent?.marginOnMobile ?? true
  const dividerToggle =
    jsonContent?.dividerToggle || jsonContent?.dividerToggle == false
      ? jsonContent?.dividerToggle
      : true
  const buttonColor = jsonContent?.buttonColor
  const cardStyle = jsonContent?.cardStyle
  const maxMobileItems = jsonContent?.maxMobileItems || 0
  const navLinkSection = jsonContent?.navLinkSection
  const [showMore, setShowMore] = useState(false)
  const getGridItem = (id, item, dividerOnMobile) => {
    return (
      <Grid
        item
        md={12}
        lg={3.5}
        className={classNames(
          (id + 1) % itemsByrow == 0 || !dividerToggle ? '' : styles['grid-item'],
          id + 1 > itemsByrow && styles['spacing-grid-item']
        )}
        key={`card-${id}`}
      >
        <Box
          data-testid={`value-prop-grid-${idContent}-card-${id}`}
          className={styles['value-prop-grid-card']}
        >
          <CardValueProp
            cardStyle={cardStyle}
            content={item}
            isMobile={isMobile}
            breakline={breakline}
            dividerOnMobile={dividerOnMobile}
            pos={id}
            className={styles['card-container']}
          />
        </Box>
      </Grid>
    )
  }

  const gridItems = (dividerOnMobile = false, max) => {
    return (
      <>
        {grid?.gridItemsCollection?.items?.map((item, idx) => {
          if (idx >= max && max !== 0) return null
          return getGridItem(idx, item, dividerOnMobile)
        })}
        {max > 0 && grid?.gridItemsCollection?.items.length > max && (
          <>
            <Collapse in={showMore}>
              <Grid container justifyContent="left" gap="1rem">
                {grid?.gridItemsCollection?.items?.map((item, idx) => {
                  if (idx < max) return null
                  return getGridItem(idx, item, dividerOnMobile)
                })}
              </Grid>
            </Collapse>
            <Grid container justifyContent="center">
              <Button
                variant="text"
                onClick={() => setShowMore(!showMore)}
                endIcon={showMore ? <ChevronUp /> : <ChevronDown />}
                track={{
                  list_name: 'VALUE PROPS ACCORDION LESS/MORE',
                  click_id: showMore ? 'Less' : 'More',
                  click_text: showMore ? 'Show less' : 'Show more',
                }}
                data-testid="show-more-less-value-props-button"
              >
                {showMore ? 'Show less' : 'Show all'}
              </Button>
            </Grid>
          </>
        )}
      </>
    )
  }

  return (
    <Section
      backgroundColor={jsonContent?.background ?? backgroundColor}
      className={classNames(styles['section'], { [styles['breakline']]: breakline })}
      variant={jsonContent?.background ?? 'bgDefault'}
      contain={isMobile ? marginOnMobile : true}
    >
      <Grid
        container
        justifyContent="center"
        data-testid={`value-prop-grid-container-${idContent}`}
        className={styles['container-grid']}
        data-no-margin={marginOnMobile}
      >
        <TitleDivider
          data-testid={`value-prop-title-divider-${idContent}`}
          eyebrow={eyebrow}
          eyebrowBlack
          eyebrowClassName={styles['eyebrow']}
          className={styles['title-divider-wrapper']}
          title={title}
          subtitle={subtitle}
          isMobile={isMobile}
          variants={variant}
          disableTitleTag={true}
          disableSubtitleTag={true}
        />

        {isMobile && carouselOnMobile ? (
          //only on mobile and carouselOnMobile set to true
          <Carousel
            className={styles['flat-carousel-wrapper']}
            carouselItems={grid?.gridItemsCollection?.items}
            itemComponent={Card}
            variant="preview-small-xs"
            idPrefix="value-props-carousel"
            withIndicators
            classNameIndicators={styles['indicators']}
            classNameActiveDot={styles['active-indicator']}
          />
        ) : containerGrid ? (
          <Grid
            container
            data-testid={`value-prop-grid-card-container-${idContent}`}
            className={classNames(styles['card-grid'], { [styles['breakline']]: breakline })}
          >
            {gridItems(dividerOnMobile, isMobile ? maxMobileItems : 0)}
          </Grid>
        ) : (
          // only for aboutUs culture
          <Grid
            item
            sm={10}
            md={8}
            lg={12}
            data-testid={`value-prop-grid-card-container-${idContent}`}
            className={classNames(styles[isMobile ? 'card-grid-column' : 'card-grid'], {
              [styles['breakline']]: breakline,
            })}
          >
            {gridItems(false, isMobile ? maxMobileItems : 0)}
          </Grid>
        )}
        {linkButton && (
          <div className={styles['button-wrapper']}>
            <LinkButton
              className={styles['button']}
              content={linkButton}
              typographicOptions={{
                variantOverride: { [BLOCKS.PARAGRAPH]: 'displayS10' },
                fontWeight: 'bold',
                'data-testid': `value-prop-button-text-${idContent}`,
                className: styles['typography'],
              }}
              color={buttonColor ? buttonColor : 'primary'}
              data-testid={`value-prop-button-${idContent}`}
              track={{
                list_name: 'Personalized Value Props CTA',
                click_id: linkButton.linkText?.content?.[0]?.content[0]?.value,
                nav_link_section: navLinkSection ?? 'Value Props',
              }}
            />
            {arrow && handwritten && (
              <>
                <div className={styles['arrow']}>
                  <MediaImageStandard
                    content={arrow.mediaContent}
                    layout="fixed"
                    height="30"
                    width="25"
                  />
                </div>
                <div className={styles['handwritten']}>
                  <MediaImageStandard
                    content={handwritten.mediaContent}
                    layout="fixed"
                    width="130"
                    height="30"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </Grid>
    </Section>
  )
}

function ValueProps({ content }) {
  const idContent = content?.fields?.sys?.id
  const {
    title,
    eyebrow,
    subtitle,
    grid,
    theme,
    linkButton,
    arrow,
    handwritten,
    backgroundConfigJson,
  } = useMemoizedContentGetter(content, [
    'title',
    'eyebrow',
    'subtitle',
    'grid',
    'theme',
    'linkButton',
    'arrow',
    'handwritten',
    'backgroundConfigJson',
  ])
  const fieldName = content?.fields?.fieldName
  return (
    <ValuePropsModule
      idContent={idContent}
      title={title}
      eyebrow={eyebrow}
      subtitle={subtitle}
      grid={grid}
      theme={theme}
      linkButton={linkButton}
      arrow={arrow}
      handwritten={handwritten}
      backgroundConfigJson={backgroundConfigJson}
      componentVariant={fieldName}
    />
  )
}

export { ValueProps, ValuePropsModule }
