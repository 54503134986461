import styles from 'components/Cards/CardAboutERP/CardAboutERP.module.scss'
import { Card } from '@achieve/sunbeam'
import { MediaImageStandard, Typography } from 'components/Contentful'
import { BLOCKS } from '@contentful/rich-text-types'

function CardAboutERP({ content, isMobile, imageWidth = 80, imageHeight = 80 }) {
  const { cardTitleText, cardText, cardImage } = content || {}
  return (
    <Card variant="none" data-testid="card-container" className={styles['card']}>
      {cardImage && (
        <MediaImageStandard
          className={styles['image']}
          content={cardImage}
          data-testid="card-media-image"
          height={imageHeight}
          layout="fixed"
          objectFit="cover"
          width={imageWidth}
        />
      )}
      {cardTitleText && (
        <Typography
          className={styles['title-typography']}
          content={cardTitleText}
          data-testid="card-title"
          fontWeight="medium"
          variantOverride={{ [BLOCKS.HEADING_3]: 'displayS20' }}
        />
      )}
      {cardText && (
        <Typography
          className={styles['text-typography']}
          content={cardText}
          data-testid="card-subtitle"
          variantOverride={{ [BLOCKS.PARAGRAPH]: isMobile ? 'displayXS30' : 'displayS10' }}
        />
      )}
    </Card>
  )
}

export { CardAboutERP }
