import styles from 'components/Cards/CardValueProp/CardValueProp.module.scss'
import { CardMedia as SunbeamCardMedia, Box } from '@achieve/sunbeam'
import { MediaImageStandard, Typography } from 'components/Contentful'
import classNames from 'classnames'

function CardValueProp({
  content,
  breakline,
  isMobile,
  dividerOnMobile = false,
  pos = 0,
  cardStyle,
}) {
  const { cardTitleText, cardText, cardImage, cardJson } = content || {}
  return (
    <Box
      variant="none"
      data-testid="card-container"
      className={classNames([
        cardStyle
          ? styles[`card-${cardStyle}`]
          : dividerOnMobile && isMobile
          ? styles['card-divider']
          : styles['card'],
        breakline && styles['breakline'],
        dividerOnMobile && isMobile && pos !== 0 && styles['card-divider-border'],
      ])}
    >
      <Box className={styles['card-img']}>
        {cardImage && (
          <SunbeamCardMedia data-testid="sunbeam-card-media">
            <MediaImageStandard
              data-testid="card-media-image"
              layout="fixed"
              width={cardJson?.imagesize ?? 60}
              height={cardJson?.imagesize ?? 60}
              content={cardImage}
            />
          </SunbeamCardMedia>
        )}
      </Box>
      <Box className={styles['card-info']}>
        {cardTitleText && (
          <Typography
            data-testid="card-title"
            variant={isMobile ? 'displayS10' : 'displayS20'}
            fontWeight="medium"
            className={styles['card-text-title']}
            content={cardTitleText}
          />
        )}

        {cardText && (
          <Typography
            data-testid="card-subtitle"
            className={styles['card-text-subtitle']}
            variant={'displayXS30'}
            component="p"
            content={cardText}
          />
        )}
      </Box>
    </Box>
  )
}

export { CardValueProp }
