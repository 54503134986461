import styles from './DirectMailerLink.module.scss'
import { Typography } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink'

const DirectMailerLink = ({ content: mailerContent, track }) => {
  return (
    <div className={styles['direct-mail-container']}>
      {mailerContent?.text && (
        <Typography
          className={styles['direct-mail-text']}
          variant="displayXS30"
          content={mailerContent.text}
        />
      )}
      <AchieveLink
        className={styles['direct-mail-link']}
        href={mailerContent.linkHref}
        track={track}
      >
        <Typography
          variant="displayXS30"
          fontWeight="bold"
          content={mailerContent?.linkText?.json?.content?.[0]?.content?.[0]?.value}
        />
      </AchieveLink>
    </div>
  )
}

export { DirectMailerLink }
